<script setup>
import { ref, getCurrentInstance, onMounted, reactive } from "vue"
import dayjs from "dayjs"
import { ElMessage, ElNotification } from 'element-plus'
import { CopyDocument } from '@element-plus/icons-vue'
import { GetTodayTime, GetTodayEndTime } from "../../tools/time"

const { appContext } = getCurrentInstance()

let searchInfo = ref({
  created_start: GetTodayTime(),
  created_end: GetTodayEndTime(),
  status: "default"
})

const handleReset = () => {
    searchInfo.value.created_start = GetTodayTime()
    searchInfo.value.created_end = GetTodayEndTime()
    searchInfo.value.trade_no = ""
    searchInfo.value.out_trade_no = ""
    searchInfo.value.merchant_name = ""
    searchInfo.value.store_name = ""
    searchInfo.value.agent_name = ""
    searchInfo.value.status = "default"
    searchInfo.value.merchant_no = ""
}

const handleSearch = () => {
  isloading.value = true
  let info = searchInfo.value
  if (Object.keys(info).length === 0) {
    queryData()
  } else {
    queryData(info)
  }
}

let trade = ref()
let pageinfo = reactive({
  total: 0,       // 总条数
  perPage: 0,     // 每页多少条
  currentPage: 0, // 当前页数
  totalPages: 0  // 总页数
})
let isloading = ref(true)

const handleMore = (row) => {
  moreinfo.value = row
  moreinfoshow.value = !moreinfoshow.value
}

const queryData = (data) => {
  isloading.value = true
  if (data) {
    appContext.config.globalProperties.$api.transaction(data).then(res => {
      if (res.status === 200) {
        trade.value = res.data
        pageinfo.total = res.pagination.total
        pageinfo.perPage = res.pagination.perPage
        pageinfo.currentPage = res.pagination.currentPage
        pageinfo.totalPages = res.pagination.totalPages
      } else {
        ElMessage.error('数据查询失败,请稍后重试')
        ElNotification({
          title: '错误信息',
          message: res.message,
          type: 'error',
        })
      }
      isloading.value = false
    }).catch(() => {
      ElMessage.error('数据查询失败,请稍后重试')
      isloading.value = false
    })
  } else {
    appContext.config.globalProperties.$api.transaction().then(res => {
      if (res.status === 200) {
        trade.value = res.data
        pageinfo.total = res.pagination.total
        pageinfo.perPage = res.pagination.perPage
        pageinfo.currentPage = res.pagination.currentPage
        pageinfo.totalPages = res.pagination.totalPages
      } else {
        ElMessage.error('数据查询失败,请稍后重试')
        ElNotification({
          title: '错误信息',
          message: res.message,
          type: 'error',
        })
      }
      isloading.value = false
    }).catch(() => {
      ElMessage.error('数据查询失败,请稍后重试')
      isloading.value = false
    })
  }
}

const handleCopy = (data) => {
  ElMessage({
    message: '复制成功',
    type: 'success',
  })
  let tempcopy = document.createElement("input")
  tempcopy.value = data
  document.body.appendChild(tempcopy)
  tempcopy.select()
  document.execCommand('Copy')
  document.body.removeChild(tempcopy) // 复制完成后，移除临时输入框
}

const pageChange = (page) => {
  hasSearch(page)
}

const hasSearch = (page) => {
  let info = searchInfo.value
  if (Object.keys(info).length === 0) {
    queryData({ page: page })
  } else {
    info.page = page
    queryData(info)
  }
}

let moreinfoshow = ref(false)
let moreinfo = ref()

onMounted(() => {
  queryData(searchInfo.value)
})
</script>

<template>
  <el-card shadow="never">
    <div class="top-search">
      <el-form :inline="true" :model="searchInfo" label-width="100px" label-position="right">
        <el-row>
          <el-col :span="6">
            <el-form-item label="交易流水号：" style="width:292px">
              <el-input v-model="searchInfo.trade_no" placeholder="请输入交易流水号" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="商户订单号：" style="width:292px">
              <el-input v-model="searchInfo.out_trade_no" placeholder="请输入商户订单号" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="商户名称：" style="width:292px">
              <el-input v-model="searchInfo.merchant_name" placeholder="请输入商户名称" />
            </el-form-item>
          </el-col>

        </el-row>

        <el-row>
          <el-col :span="6">
            <el-form-item label="代理商名称：" style="width:292px">
              <el-input v-model="searchInfo.agent_name" placeholder="请输入代理商名称" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="创建时间：" style="width:292px">
              <el-date-picker v-model="searchInfo.created_start" type="datetime" format="YYYY-MM-DD HH:mm:ss"
                value-format="YYYY-MM-DD HH:mm:ss" placeholder="请输入创建时间" />
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="门店名称：" style="width:292px">
              <el-input v-model="searchInfo.store_name" placeholder="请输入门店名称" />
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <div class="search-btn">
              <el-button @click="handleReset()">重置</el-button>
            </div>
          </el-col>
        </el-row>


        <el-row>
          <el-col :span="6">
            <el-form-item label="商户号：" style="width:292px">
              <el-input v-model="searchInfo.merchant_no" placeholder="请输入商户号" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="截至时间：" style="width:292px">
              <el-date-picker v-model="searchInfo.created_end" type="datetime" format="YYYY-MM-DD HH:mm:ss"
                value-format="YYYY-MM-DD HH:mm:ss" placeholder="请输入截至时间" />
            </el-form-item>
          </el-col>








          <el-col :span="6">
            <el-form-item label="订 单 状 态:" style="width:292px">
              <el-select v-model="searchInfo.status" placeholder="请输选择订单状态" clearable>
                <el-option value="default" label="交易完成" />
                <el-option value="10" label="未支付" />
                <el-option value="3" label="支付成功" />
                <el-option value="5" label="部分退款" />
                <el-option value="6" label="已退款" />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="3">
            <div class="search-btn">
              <el-button type="primary" @click="handleSearch()">查询</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>

    </div>
  </el-card>
  <div class="content">
    <el-table :data="trade" style="width: 100%" v-loading="isloading" size="small">
      <el-table-column label="序号" width="80" type="index" align="center">
        <template #default="scope">
          {{ (pageinfo.currentPage - 1) * pageinfo.perPage + scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="trade_no" label="交易流水号" width="160">
        <template #default="scope">
          <el-tooltip :content=scope.row.trade_no placement="top">
            <div v-if="scope.row.trade_no.length > 10">
              <span>{{ scope.row.trade_no.substr(0, 9) + '...' }}</span>
              <el-icon>
                <CopyDocument @click="handleCopy(scope.row.trade_no)" style="color:#52acff" />
              </el-icon>
            </div>
            <div v-else>
              <span>{{ scope.row.trade_no }}</span>
              <el-icon>
                <CopyDocument @click="handleCopy(scope.row.trade_no)" style="color:#52acff" />
              </el-icon>
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="out_trade_no" label="商户订单号" width="160">
        <template #default="scope">
          <el-tooltip :content=scope.row.out_trade_no placement="top">
            <div v-if="scope.row.out_trade_no !== ''">
              <div v-if="scope.row.out_trade_no.length > 10">
                <span>{{ scope.row.out_trade_no.substr(0, 10) + '...' }}</span>
                <el-icon>
                  <CopyDocument @click="handleCopy(scope.row.out_trade_no)" style="color:#52acff" />
                </el-icon>
              </div>
              <div v-else>
                <span>{{ scope.row.out_trade_no }}</span>
                <el-icon>
                  <CopyDocument @click="handleCopy(scope.row.out_trade_no)" style="color:#52acff" />
                </el-icon>
              </div>
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="merchant_name" label="商户名称" width="160" />
      <el-table-column prop="merchant_no" label="商户号" />
      <el-table-column prop="agent_name" label="代理商名称" />
      <el-table-column prop="pay_amount" label="支付金额">
        <template #default="scope">
          <span>{{ scope.row.pay_amount }} 元</span>
        </template>
      </el-table-column>
      <el-table-column prop="total_amount" label="订单金额">
        <template #default="scope">
          <span>{{ scope.row.total_amount }} 元</span>
        </template>
      </el-table-column>
      <el-table-column prop="refund_amount" label="退款金额">
        <template #default="scope">
          <span>{{ scope.row.refund_amount }} 元</span>
        </template>
      </el-table-column>
      <el-table-column prop="status_format" label="支付状态">
        <template #default="scope">
          <el-tag v-if="scope.row.status_format == '支付成功'" type="success">{{ scope.row.status_format }}</el-tag>
          <el-tag v-else-if="scope.row.status_format == '支付失败'" type="danger">{{ scope.row.status_format }}</el-tag>
          <el-tag v-else type="info">{{ scope.row.status_format }}</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="is_settlement" label="是否结算" width="80">
        <template #default="scope">
          <span v-if="scope.row.is_settlement==0">否</span>
          <span v-else>是</span>
        </template>
      </el-table-column> -->
      <el-table-column prop="payment_channel" label="支付渠道" width="120" />
      <el-table-column prop="payment_method" label="支付方式" />
      <el-table-column prop="created_at" label="创建时间" width="200">
        <template #default="scope">
          <span>{{ dayjs(scope.row.created_at).format("YYYY-MM-DD H:mm:ss") }} </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <el-link :underline="false" @click="handleMore(scope.row)" type="primary">详情</el-link>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty description="空数据" :image-size="200" />
      </template>
    </el-table>
    <div class="pagination">
      <el-pagination background layout="total, prev, pager, next" :total=pageinfo.total @current-change="pageChange"
        :page-size=pageinfo.perPage :current-page=pageinfo.currentPage />
    </div>
  </div>
  <!-- more -->
  <el-dialog v-model="moreinfoshow" title="订单详情" width="77%" :show-close=false>
    <div class="moreinfo">
      <el-row>
        <el-col :span="3">
          <div class="moreinfo-title">
            交易流水号
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.trade_no }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            渠道交易号
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.channel_trade_no }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            商户订单号
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.out_trade_no }}
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="moreinfo-title">
            商户名称
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.merchant_name }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            商户编号
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.merchant_no }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            门店名称
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.store_name }}
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="moreinfo-title">
            代理商名称
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.agent_name }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            支付设备
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.serial_number }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            交易费率
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.rate }}%
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="moreinfo-title">
            手续费
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.fee_amount }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            订单金额(元)
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.total_amount }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            支付金额(元)
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.pay_amount }}
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="moreinfo-title">
            优惠金额(元)
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.discount_amount }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            商户实收金额(元)
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.receipt_amount }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            退款金额(元)
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.refund_amount }}
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="moreinfo-title">
            订单状态
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            <el-tag v-if="moreinfo.status_format == '支付成功'" type="success" effect="dark" size="small">{{
        moreinfo.status_format }}</el-tag>
            <el-tag v-else-if="moreinfo.status_format == '支付失败'" type="danger" effect="dark" size="small">{{
        moreinfo.status_format }}</el-tag>
            <el-tag v-else type="info" effect="dark" size="small">{{ moreinfo.status_format }}</el-tag>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            是否结算
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            <el-tag v-if="moreinfo.is_settlement == 1" type="success" size="small">是</el-tag>
            <el-tag v-else type="danger" size="small">否</el-tag>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            支付方式
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.payment_method }}
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="moreinfo-title">
            创建时间
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ dayjs(moreinfo.created_at).format("YYYY-MM-DD H:mm:ss") }}
          </div>
        </el-col>

        <el-col :span="3">
          <div class="moreinfo-title">
            备注
          </div>
        </el-col>
        <el-col :span="13">
          <div class="moreinfo-content">
            {{ moreinfo.remarks }}
          </div>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <el-button type="primary" @click="moreinfoshow = false">关闭</el-button>
    </template>
  </el-dialog>
  <!-- more end -->
</template>

<style lang="less" scoped>
.top-search {
  width: 100%;

  .search-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.content {
  margin-top: 1.24rem;

  .pagination {
    margin: 1.25rem 0;
    display: flex;
    justify-content: flex-end;
  }
}

.moreinfo {
  width: 100%;
  border: 1px solid #f0f0f0;
  margin: 0 auto;

  .moreinfo-title,
  .moreinfo-content {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    font-size: 12px;
    height: 40px;
  }

  .moreinfo-title {
    background-color: #fdfbfb;
  }

  .moreinfo-content {
    background: #fff;
  }
}
</style>
